@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;500&family=Roboto+Slab:wght@100;200;300;400;700&display=swap');

* {
    font-family: 'Nunito', sans-serif;
    font-family: 'Roboto Slab', serif;
    margin-bottom: 0 !important;
}

/* .sub_menu{
    position: absolute;
    z-index: 1010;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s, top 0.5s, left 0.5s, right 0.5s;
    width: 260px;
    font-size: 13px;
    line-height: 2.2em;
} */
.header_banner {
    /* bottom: 1.25rem;
    color: #fff;
    left: 0;
    line-height: 1061%;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    position: absolute;
    text-align: start;
    top: 52%; */

    position: absolute;
    top: 50%;
    left: 21%;
    transform: translate(-50%, -50%);
    line-height: 1061%;
    letter-spacing: 0.2em;

    color: #2B327B;

}

.header_who {
    line-height: 120%;
    color: #2A93C0;
    font-weight: 900;
}

.header_about {
    line-height: 180%;
    color: #2B327B;
    font-weight: 900;
}

.btn_learn_more {
    background: #2B327B;
    border-radius: 10px;
    color: white !important;
}

.btn_learn_more:hover {
    color: white !important;
}

/* .banner{
    height: 700px;
} */

/* .header_who,.header_why,.header_feautures, .header_features_20, .header_LearnbyZ, .card_title_main_reason,.text_color {
    line-height: 180%;
    color: #ED9A22;
} */


/* .about_description {
    padding-right: 55px;
    padding-top: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 180%;
    text-align: center;
} */

.about_description {
    top: 1200px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 173%;
    text-align: justify;
}

.school_description {
    top: 1200px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 173%;
    text-align: justify;
}

/* .bg_carousel{
    background: #F0F1F0;
} */
.header_letest_event {
    line-height: 180%;
    color: #4083C5;
    text-align: center;
    font-weight: 900;
}

.letest_event_description {
    /* padding-right: 55px; */
    padding-top: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 180%;
    text-align: center;
}

.letest_event_card_title {
    font-size: 13px;
    text-align: justify;
    /* color: #455A64; */
    /* line-height: 230%; */
}

.letest_event_description_card {
    font-size: 13px;
    text-align: justify;
    margin-top: 8px;
    /* color: #455A64; */
    /* line-height: 230%; */
}

.header_event_card_month {
    font-weight: 800;
    font-size: 13px;
    line-height: 124.9%;
    /* identical to box height, or 25px */
    text-align: center;
    color: #4083C5;
}

.header_event_card_date {
    font-weight: 600;
    line-height: 124.9%;
    text-align: center;
}

.card_bg {
    background: #F0F1F0;
    border-radius: 10px;
}

.header_gallery {
    line-height: 180%;
    color: #4083C5;
    text-align: center;
    font-weight: 900;
    padding-bottom: 2%;
}

.btn_gallery_load_more {
    /* line-height: 180%; */
    text-align: center;
    padding-top: 3%;
    padding-bottom: 2%;
}

.btn_load_more {
    background: #2B327B;
    border-radius: 10px;
    color: white !important;
}


/* about us */
.banner_about_us {
    position: relative;
    max-width: 100%;
    margin: 0 auto;
}

.banner_about_us .content_img {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.22);
    /* rgb(0, 0, 0) Fallback color */
    /* background: rgba(253, 236, 245, 0.22); rgba(0, 0, 0, 0.5) Black background with 0.5 opacity */
    color: hsla(0, 23%, 95%, 0.856);
    width: 100%;
    padding: 30px;
}

.header_about_our_sec {
    line-height: 120%;
    font-weight: 900;
}

.header_about_our, .header_at {
    color: #2A93C0;
    font-weight: 900;
}

.header_about_riverdale {
    line-height: 180%;
    color: #2B327B;
    font-weight: 900;
    padding-bottom: 1%;
}

.header_our_mission, .header_our_vision {
    /* line-height: 180%; */
    color: #2B327B;
    font-weight: 900;
    padding-bottom: 4%;
}

.our_mission_description, .our_vision_description {
    top: 1200px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 173%;
    text-align: justify;
}

/* about us */

/* admission */
.banner_admission {
    position: relative;
    max-width: 100%;
    margin: 0 auto;
}

.banner_admission .content_img {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.22);
    /* rgb(0, 0, 0) Fallback color */
    /* background: rgba(253, 236, 245, 0.22); rgba(0, 0, 0, 0.5) Black background with 0.5 opacity */
    color: hsla(0, 23%, 95%, 0.856);
    width: 100%;
    padding: 30px;
}

.admission_description {
    font-size: 14px;
    line-height: 173%;
    text-align: justify;
}

.admission_list_step {
    font-size: 14px;
    line-height: 173%;
    text-align: justify;
}

.admission_step_header {
    color: #2A93C0;
    /* font-style: normal; */
    font-weight: normal;
    font-size: 14px;
    line-height: 300%;

}

.admission_step_2_ul, .admission_step_3_ul, .admission_step_4_ul {
    list-style: circle;
}

/* admission */

/* contact us */

.banner_contact_us {
    position: relative;
    max-width: 100%;
    margin: 0 auto;
}

.banner_contact_us .content_img {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.22);
    /* rgb(0, 0, 0) Fallback color */
    /* background: rgba(253, 236, 245, 0.22); rgba(0, 0, 0, 0.5) Black background with 0.5 opacity */
    color: hsla(0, 23%, 95%, 0.856);
    width: 100%;
    padding: 30px;
}

.header_get_touch,
.header_contact_us {
    line-height: 180%;
    padding-bottom: 20px;
}

.contact_us_description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 173%;
}

.contact_us_social_icon {
    /* color: #ED9A22; */
    color: #2A93C0;
}

.btn_free_trail_now {
    /* background: #ED9A22; */
    background: #2A93C0;
    border-radius: 10px;
    color: white;
}

.text_color {
    line-height: 180%;
    /* color: #ED9A22; */
    color: #2A93C0;
}

.btn_free_trail_now:hover {
    color: white !important;
}

/* contact us */

/* pay online */
.banner_pay_online {
    position: relative;
    max-width: 100%;
    margin: 0 auto;
}

.banner_pay_online .content_img {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.22);
    /* rgb(0, 0, 0) Fallback color */
    /* background: rgba(253, 236, 245, 0.22); rgba(0, 0, 0, 0.5) Black background with 0.5 opacity */
    color: hsla(0, 23%, 95%, 0.856);
    width: 100%;
    padding: 30px;
}

.header_payment_steps, .header_payment_scan_qr {
    color: #2B327B;
    font-weight: 900;
}

.payment_steps_description {
    font-size: 14px;
    line-height: 173%;
    text-align: justify;
}

.payment_steps_ul {
    list-style: circle;
    padding-top: 20px;
}

.payment_steps_list {
    font-size: 14px;
    line-height: 180%;
    text-align: justify;
}

.payment_steps_done {
    font-size: 14px;
    /* line-height: 173%; */
    padding-top: 20px;
    text-align: justify;
}

.payment_steps_send {
    font-size: 14px;
    /* line-height: 173%; */
    padding-top: 45px;
    color: #2A93C0;
    text-align: justify;
}

/* pay online */
/* top navbar header start */
.dropdown:hover .dropdown-menu{
    display: block;
}

.dropdown-menu {
    background: #F8F9FA !important;
    border: none !important;
    border-radius: 0.25rem;
}
.navbar-nav .nav-link:hover {
    color: #fff !important;
    background: #939393 !important;
}
.navbar-nav .nav_link_pay_online:hover {
    color: #fff !important;
    background: #2B327B !important;
}

.dropdown-toggle {
    display: inline-block !important;
    border-right: none !important;
    border-bottom: none !important;
    border-left: none !important;
}

.dropdown-toggle::after {
    border-right: none !important;
    border-bottom: none !important;
    border-left: none !important;
}

.dropdown-item {
    padding: 3px 8px !important;

}
 .dropdown-item:hover{
    color: #fff !important;
    background: #939393 !important;
}

/* top navbar header end */

/* academics start */
.banner_academic_program {
    position: relative;
    max-width: 100%;
    margin: 0 auto;
}

.banner_academic_program .content_img {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.22);
    /* rgb(0, 0, 0) Fallback color */
    /* background: rgba(253, 236, 245, 0.22); rgba(0, 0, 0, 0.5) Black background with 0.5 opacity */
    color: hsla(0, 23%, 95%, 0.856);
    width: 100%;
    padding: 30px;
}

/* academics program start */
.header_early_years, .header_primary_years, .header_middle_years {
    color: #2A93C0;
    line-height: 180%;
    font-weight: 900;
}

.header_form_play, .header_from_grade {
    padding-top: 2%;
    padding-bottom: 2%;
    line-height: 150%;
    color: #2B327B;
    font-weight: 900;
}

.early_years_description, .primary_years_description, .middle_years_description {
    top: 1200px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 173%;
    text-align: justify;
}

/* notice/academics start */
.pdf-container{
    width: 100%;
    height: 800px;
    background-color: #e4e4e4;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .error-msg{
    width: 100%;
    color: red;
    font-size: 14px;
    font-weight: 600;
  }
/* notice/academics end*/

/* academics program end */

/* The Outdoor Classroom start*/
.header_real_world_learning {
    font-weight: 900;
    color: #4083C5;
}

.nature_description, .lgs_description {
    font-size: 14px;
    line-height: 173%;
    padding-top: 2%;
    text-align: justify;
}

.field_trips_ul {
    list-style: circle;
    padding-top: 20px;
}

.field_trips_list {
    font-size: 14px;
    line-height: 180%;
    text-align: justify;
}

.field_trips_description {
    font-size: 14px;
    padding-top: 2%;
    text-align: justify;
}

.trip_done {
    font-size: 14px;
    /* line-height: 173%; */
    padding-top: 20px;
    text-align: justify;
}

/* The Outdoor Classroom end*/
/* academics */





/* Info Center start*/
.banner_event_news {
    position: relative;
    max-width: 100%;
    margin: 0 auto;
}

.banner_event_news .content_img {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.22);
    /* rgb(0, 0, 0) Fallback color */
    /* background: rgba(253, 236, 245, 0.22); rgba(0, 0, 0, 0.5) Black background with 0.5 opacity */
    color: hsla(0, 23%, 95%, 0.856);
    width: 100%;
    padding: 30px;
}

.banner_gallery {
    position: relative;
    max-width: 100%;
    margin: 0 auto;
}

.banner_gallery .content_img {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.22);
    /* rgb(0, 0, 0) Fallback color */
    /* background: rgba(253, 236, 245, 0.22); rgba(0, 0, 0, 0.5) Black background with 0.5 opacity */
    color: hsla(0, 23%, 95%, 0.856);
    width: 100%;
    padding: 30px;
}

.banner_alumni {
    position: relative;
    max-width: 100%;
    margin: 0 auto;
}

.banner_alumni .content_img {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.22);
    /* rgb(0, 0, 0) Fallback color */
    /* background: rgba(253, 236, 245, 0.22); rgba(0, 0, 0, 0.5) Black background with 0.5 opacity */
    color: hsla(0, 23%, 95%, 0.856);
    width: 100%;
    padding: 30px;
}

.alumni_description {
    font-size: 14px;
    line-height: 173%;
    text-align: justify;
}

.school_calendar {
    /* margin-top: 100px; */
    padding-top: 70px;
    padding-bottom: 40px;

}

/* School Calendar schedule*/

.banner_school_calendar {
    position: relative;
    max-width: 100%;
    margin: 0 auto;
}

.banner_school_calendar .content_img {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.22);
    /* rgb(0, 0, 0) Fallback color */
    /* background: rgba(253, 236, 245, 0.22); rgba(0, 0, 0, 0.5) Black background with 0.5 opacity */
    color: hsla(0, 23%, 95%, 0.856);
    width: 100%;
    padding: 30px;
}

.schedule_playgroup, .schedule_class_2 {
    /* left: 195px;
    top: 1133px; */
    padding: 10px 110px;
    background: #4083C5;
    box-shadow: 0px 0px 50px 8px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

.playgroup_text, .class_2_text {
    text-align: center;
    padding-top: 15px;
    color: white;
}

/* School Calendar schedule*/

/* Info Center end*/


/* curricullum overview start */
.header_curricullum_overview,
.header_curricullum_early_years,
.header_curricullum_areas,
.header_curricullum_middle_years {
    padding-bottom: 2%;
    line-height: 180%;
    color: #4083C5;
    font-weight: 900;
}

/* .header_curricullum_areas{
    font-style: normal;
    font-weight: normal;
    padding-bottom: 2%;
} */
.curricullum_rds_description,
.early_years_description,
.middle_years_description {
    /* top: 1200px; */
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 173%;
    text-align: justify;
}

/* thead {
    border-bottom-width: 2px;
  } */
.table> :not(:first-child) {
    border-color: var(--bs-gray-200);
}


.table-hover-cells>tbody>tr>th:hover,
.table-hover-cells>tbody>tr>td:hover {
    background-color: #f5f5f5;
}

.bg_card_syllabus {
    background: #4083C5;
    box-shadow: 0px 0px 50px 8px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    border: none !important;
}

.link_syllabus {
    color: #fff;
}
.link_syllabus:hover{
    color: #fff;
}

/* curricullum overview end */


