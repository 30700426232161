.header_sinewy_footer {
    line-height: 180%;
}

.sinewy_footer_description {
    margin-top: 20px;
    /* padding-right: 55px; */
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 173%;
    text-align: justify;
}

.sinewy_footer_social_icon {
    /* padding-right: 55px; */
    margin-top: 25px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: justify;
}

.header_link_footer {
    line-height: 180%;
    color: #2A93C0;
}

.link_footer {
    line-height: 180%;
    text-decoration: none;
}

.header_contact_us_footer {
    line-height: 180%;
    color: #2A93C0;
}

.contact_us_footer {
    line-height: 180%;
}

.footer_bg {
    background: #EDF4FF;
}

.footer_copyright {
    text-align: center;
    font-size: small;
    background: #455A64;
    color: white;
    padding: 10px;

}